<template>
  <tabs
    :list-key="listKey"
    v-model="data"
    :options="options"
    :metaTab="metaTab"
  />
</template>

<script>
import tabs from "@/modules/base/components/tabs.vue"
import itemMixins from "@/components/liffList/listFilter/item/item.js";
export default {
  mixins: [itemMixins],
  props: {
    filterValue: {
      type: [Number,String],
      default: 0,
    },
  },
  components: {
    tabs
  },
  data: () => ({
    data: 0,
    isRun : false
  }),
  computed: {
    options() {
      if(typeof this.config.options == 'function') {
        return this.config.options(this.formMeta)
      }
      return this.config.options.map((option) => {
        if (option.value == undefined) return option;
        option.value = `${option.value}`;
        return option;
      });
    },
    metaTab() {
      return this.config.metaTab
    },
  },
  watch: {

    filterValue: {
      immediate: true,
      async handler() {
        await this.$nextTick();
        this.data = this.options.findIndex(
          (option) => {
            return option.value === `${this.filterValue}`
          }
        );
      },
    },
    data() {
      let option  = this.options.find( (data,index) => {
        return this.data == index
      } );
      if(!option) return
      
      this.setValue(option.value);
    },
  },
  methods: {
    async setValue( value ) {
      this.value = value;
      this.$root.$emit(`start-liff-list-filter`);
    },
  },
};
</script>