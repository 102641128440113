export default {
  props: {
    listKey: String,
    name: String,
    config: Object,
    setupItemsCallback: Function,
  },
  data: () => ({
    value: null,
  }),
  computed: {
    filterConfig() {
      return this.$store.getters[`list/${this.listKey}/filterConfig`];
    },
    listFilter() {
      return this.$store.getters[`list/${this.listKey}/filter`];
    },
    listActions() {
      return this.$store.getters[`list/${this.listKey}/actions`];
    },
    formMeta() {
      return this.$store.getters[`list/${this.listKey}/meta`]
    },
  },
  watch: {
    listFilter: {
      deep: true,
      immediate: true,
      handler() {
        const filter = window.eagleLodash.cloneDeep(this.listFilter);
        if(!filter) return
        this.value = filter[this.name];
      },
    },
  },
  created() {
    this.setupItemsCallback({
      key: this.name,
      callback: (filter) => {
        if (this.value == null) return;
        filter[this.name] = this.value;
      },
    });
  },
};
